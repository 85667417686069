import { AddressableItem } from "../../common/addressable-item";
import { StripeAccountStatus } from "@shared/stripe-utils";

export class SiteBase extends AddressableItem {
  public id: string;
  public active: boolean;
  public applied_brand_id: string;
  public default_payment_plan_id: string;
  public email_address: string;
  public active_mailbox_address: string;
  public google_review_url: string;
  public logo_url: string;
  public name: string;
  public nhs_country: string;
  public nickname: string;
  public opening_hours: string;
  public phone_number: string;
  public practice_id: string;
  public start_date: Date;
  public stripe_account_id: string;
  public stripe_account_status: StripeAccountStatus;
  public website: string;
}
